import "./Contact.css";

function Contact() {
  return (
    <div className="container">
      <p>
        Contact Me At: palmercurrie@gmail.com or linkedin.com/in/palmercurrie/
      </p>
    </div>
  );
}

export default Contact;
